import React from "react"
import { Router } from '@reach/router'
import { connect } from "react-redux";
import SearchPage from "../templates/SearchPage";
const App = ({ location }) => {
  return (
    <div className="app">
      <Router>
        <SearchPage path="recherche/:word" location={location} />
        <SearchPage path="/recherche/" location={location} />
      </Router>
    </div>
  )
};

export default connect()(App);
