import React, { useEffect } from "react"
import Results from "./results/Results"
import { connect } from "react-redux";
import { fetchSolr, resultSetWord, setLastListPath } from "../redux/actions/SolrActions"
import { navigate } from "gatsby"
import { getNodePath } from '../utils'
import Page from "./Page";

const SearchPage = ({ word, loading, currentWord, lastWord, dispatch, isMap, location, items, ...props }) => {

  word = word || '';

  useEffect(() => {
    if (word !== currentWord && word !== "") {
      dispatch(resultSetWord(word));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [word]);
  useEffect(() => {
    if (!loading && (items === false || currentWord !== lastWord) && currentWord !== "") {
      dispatch(fetchSolr(currentWord));
    }
  }, [loading, currentWord, lastWord, props, dispatch, items]);

  useEffect(() => {
    if (!loading && items) {
      dispatch(setLastListPath(location.pathname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, items]);

  useEffect(() => {
    if (items.length === 1 && currentWord === word && !loading) {
      if (items[0].type === "les_entreprises") {
        dispatch(resultSetWord(""));
        navigate(getNodePath(items[0], isMap));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  const crumbs = [
    {
      path: '/',
      title: "Recherche",
    }, {
      path: '/recherche/' + word,
      title: word,
    }
  ]
  return (
    <Page crumbs={crumbs} title={'Résultats de recherche "' + word + '"'} description={'Recherchez "' + word + '" sur Plan.nc'} location={location} filters={true}>
      <Results loading={loading} results={items} />
    </Page>
  )
}


const mapStateToProps = state => ({
  currentWord: state.solr.word,
  lastWord: state.solr.lastWord,
  items: state.solr.items,
  loading: state.solr.loading,
  isMap: state.global.isMap,
});

export default connect(mapStateToProps)(SearchPage);